// 序列化数字
export type IFormatCountReturn = { unit: string; val: string; str: string };
export function formatCount(_count: number | string, decimal = 2) {
  const count = Number(_count as string);
  const res = { unit: undefined } as IFormatCountReturn;
  if (!count) {
    res.val = _count as string;
  } else if (Math.log10(count) >= 4) {
    // 万
    const inter = count / 10 ** 4;
    res.val = String(+inter.toFixed(decimal));
    res.unit = 'W';
  } else {
    res.val = String(+count.toFixed(decimal));
  }
  res.str = `${res.val || ''}${res.unit || ''}`;
  return res;
}

export function humpToUnderline(str) {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase();
}
/**
 * 格式化为货币格式
 * @param value
 */
export function formatMoney(value: string | number, prefix = '¥ '): string {
  return `${prefix}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const aliDomain = ['assets-v2.cbndata.com'];

/**
 * 图片裁切辅助函数
 * @param url 图片URL
 * @param w 裁切的最大宽，如果比原图的宽大，则失效, 在mac屏幕下，一般裁切宽度是css宽度的2倍。方法会动态判断
 * @param q 图片质量，仅后缀为jpg时支持
 * @param height 裁切的最大高度
 * @description 图片暂时支持对于 https://cf.dtcj.com/ https://images.dtcj.com/（七牛云）的裁切
 * @see https://developer.qiniu.com/dora/1279/basic-processing-images-imageview2
 */
export const imageFormat = (url: string, w?: number, h?: number, q = 75) => {
  if (!url) return null;
  const isLink = /^http(s)?:\/\/(.*?)\//.test(url);
  const isAliOss = aliDomain.some(e => url.indexOf(e) > -1);
  if (!isLink || !isAliOss) return url;
  const canUseQuality = /\.(jpg|jpeg)$/.test(url);
  let fileSuffix = '?x-oss-process=image/auto-orient,1/resize,m_lfit';
  if (w) {
    fileSuffix += `,w_${parseInt('' + 2 * w)}`;
  }
  if (h) {
    fileSuffix += `,h_${parseInt('' + 2 * h)}`;
  }
  if (canUseQuality) {
    fileSuffix += `/quality,q_${q}`;
  }
  fileSuffix += `/format,webp/interlace,1`;
  return url + fileSuffix;
  // ?x-oss-process=image/auto-orient,1/resize,m_fill,w_200,h_200/quality,q_90
  // }
};
